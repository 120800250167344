import { html } from 'htm/preact'

function Logo () {
    return html`<svg width="169" height="39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.582 10.543h-7.704V28.72h3.052V21.83h4.688c3.307 0 5.85-2.2 5.85-5.626-.036-3.462-2.47-5.662-5.886-5.662Zm-.291 8.584h-4.36v-5.843h4.36c1.926 0 3.125 1.118 3.125 2.921-.036 1.804-1.235 2.922-3.125 2.922ZM68.484 10.543h-2.835V28.72h2.835V10.543ZM82.075 17.793h-.255c-.8-1.154-2.107-2.236-4.47-2.236-3.161 0-5.923 2.596-5.923 6.744 0 4.183 2.762 6.78 5.923 6.78 2.399 0 3.707-1.082 4.47-2.272h.255v1.911h2.834V15.917h-2.834v1.876Zm-3.889 8.836c-2.253 0-3.888-1.587-3.888-4.328 0-2.705 1.635-4.328 3.888-4.328s3.889 1.623 3.889 4.328-1.636 4.328-3.889 4.328ZM95.555 15.7c-2.471 0-3.598 1.083-4.179 2.2h-.254v-1.983h-2.799V28.72h2.835v-6.564c0-2.632 1.38-4.111 3.525-4.111 1.926 0 3.052 1.01 3.052 3.174v7.501h2.871v-7.682c0-3.282-2.108-5.337-5.051-5.337ZM110.018 26.629c-2.071 0-3.597-1.443-3.706-3.39h9.775v-1.155c0-3.93-2.507-6.527-6.25-6.527-3.779 0-6.505 2.74-6.505 6.708 0 4.039 2.653 6.816 6.577 6.816 3.198 0 4.761-1.659 5.597-2.957l-2.471-1.082c-.509.865-1.418 1.587-3.017 1.587Zm-.181-8.62c1.926 0 3.198 1.154 3.343 3.03h-6.832c.255-1.84 1.526-3.03 3.489-3.03ZM123.247 10.543h-2.87v4.184h-3.489v2.308h3.489v9.052c0 1.623 1.053 2.633 2.616 2.633h3.634v-2.344h-2.653c-.472 0-.727-.253-.727-.794V17h3.78V14.69h-3.78v-4.148ZM139.163 17.793h-.254c-.8-1.154-2.108-2.236-4.47-2.236-3.161 0-5.923 2.596-5.923 6.744 0 4.183 2.762 6.78 5.923 6.78 2.399 0 3.707-1.082 4.47-2.272h.254v1.911h2.835V15.917h-2.835v1.876Zm-3.924 8.836c-2.253 0-3.889-1.587-3.889-4.328 0-2.705 1.636-4.328 3.889-4.328s3.888 1.623 3.888 4.328-1.635 4.328-3.888 4.328ZM148.432 17.684h-.254v-1.767h-2.798V28.72h2.834v-7.574c0-1.947 1.235-2.849 2.943-2.849h1.999v-2.416h-1.49c-1.671 0-2.725.469-3.234 1.803ZM165.073 15.917l-3.634 11.83h-.29l-3.671-11.83h-3.089l4.216 12.803h2.434l-1.889 5.193h3.089l5.959-17.996h-3.125Z" fill="#fff"/>
    <path d="M19.029 38.292c10.41 0 18.849-8.377 18.849-18.71S29.438.872 19.028.872C8.619.872.18 9.25.18 19.582s8.439 18.71 18.849 18.71Z" fill="#1A1524"/>
    <path d="m10.572 30.71 3.47-4.9h4.383c3.433 0 5.597-2.006 5.597-5.233 0-3.271-2.164-5.277-5.597-5.277h-7.853v15.41Z" fill="url(#a)"/>
    <path d="m27.486 25.477-3.471-4.9h-4.382c-3.433 0-5.598-2.005-5.598-5.232 0-3.271 2.165-5.277 5.598-5.277h7.853v15.41Z" fill="url(#b)"/>
    <path d="M18.418 15.3h-4.383v.045c0 3.22 2.165 5.232 5.598 5.232h4.382c0-3.271-2.165-5.277-5.598-5.277Z" fill="url(#c)"/>
    <defs>
      <linearGradient id="a" x1="10.572" y1="30.709" x2="27.523" y2="22.901" gradientUnits="userSpaceOnUse">
        <stop stop-color="#36F0EA"/>
        <stop offset=".733" stop-color="#8474EA"/>
      </linearGradient>
      <linearGradient id="b" x1="14.452" y1="20.5" x2="27.409" y2="9.975" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC200"/>
        <stop offset=".644" stop-color="#FF3E7A"/>
      </linearGradient>
      <linearGradient id="c" x1="14.035" y1="20.577" x2="23.982" y2="15.239" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFE593"/>
        <stop offset="1" stop-color="#FFC200"/>
      </linearGradient>
    </defs>
  </svg>`
}

module.exports = Logo
