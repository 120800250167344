"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var md5hash_1 = require("./md5hash");
function hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    return r + "," + g + "," + b;
}
function invertHex(hex) {
    return (Number("0x1" + hex) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}
exports.invertHex = invertHex;
function generatePath(curveVal, posVal, index) {
    var cVal = curveVal;
    var bigC = 300 - cVal;
    var pos = posVal;
    return "m 150 " + (100 + pos + 200 * index) + " Q " + bigC + " " + cVal + " " + (200 - pos - 200 * index) + " 150 Q " + bigC + " " + bigC + " 150 " + (200 - pos - 200 * index) + " Q " + cVal + " " + bigC + " " + (100 + pos + 200 * index) + " 150 Q " + cVal + " " + cVal + " 150 " + (100 + pos + 200 * index) + " z";
}
exports.generatePath = generatePath;
function hydrateSVGBlog(data) {
    return "<svg width=\"300\" height=\"300\" viewBox=\"0 0 300 300\" xmlns=\"http://www.w3.org/2000/svg\"><rect id=\"bg\" width=\"300\" height=\"300\" fill=\"rgb(" + hexToRgb(data.background) + ")\" /><path d=\"" + data.paths[0] + "\" fill=\"rgb(" + hexToRgb(data.colors[0]) + ")\" /><path d=\"" + data.paths[1] + "\" fill=\"rgb(" + hexToRgb(data.colors[1]) + ")\" /><path d=\"" + data.paths[2] + "\" fill=\"rgb(" + hexToRgb(data.colors[2]) + ")\" /></svg>";
}
function generateFromString(id) {
    var idArr = md5hash_1.md5(id)
        .split("")
        .filter(function (el) { return el !== "-"; });
    var backgroundColor = idArr.splice(0, 6).join("");
    var elColor = idArr.splice(idArr.length - 6, idArr.length).join("");
    var arr = __spreadArrays(Array(10)).map(function () {
        return parseInt(idArr.splice(0, 2).join(""), 16);
    });
    var data = {
        paths: [
            generatePath(arr[0], arr[1], 2),
            generatePath(arr[2], arr[3], 1),
            generatePath(arr[4], arr[5], 0),
        ],
        colors: [elColor, invertHex(elColor), invertHex(backgroundColor)],
        background: backgroundColor,
    };
    return hydrateSVGBlog(data);
}
exports.generateFromString = generateFromString;
