'use strict';

module.exports = all;

/* Visit all children of `parent`. */
function all(parent) {
  var self = this;
  var children = parent.children;
  var length = children.length;
  var results = [];
  var index = -1;

  while (++index < length) {
    results[index] = self.visit(children[index], parent);
  }

  return results;
}
