'use strict';

module.exports = orderedItems;

/* Visit ordered list items.
 *
 * Starts the list with
 * `node.start` and increments each following list item
 * bullet by one:
 *
 *     2. foo
 *     3. bar
 *
 * In `incrementListMarker: false` mode, does not increment
 * each marker and stays on `node.start`:
 *
 *     1. foo
 *     1. bar
 */
function orderedItems(node) {
  var self = this;
  var fn = self.visitors.listItem;
  var increment = self.options.incrementListMarker;
  var values = [];
  var start = node.start;
  var children = node.children;
  var length = children.length;
  var index = -1;
  var bullet;

  while (++index < length) {
    bullet = (increment ? start + index : start) + '.';
    values[index] = fn.call(self, children[index], node, index, bullet);
  }

  return values.join('\n');
}
